@charset "utf-8";

// Import a Google Font
@import url("https://fonts.googleapis.com/css2?family=Cabin:ital@0;1&display=swap");

@import "bulma/sass/utilities/initial-variables.sass";
@import "bulma/sass/utilities/functions.sass";

$family-sans-serif: "Cabin", sans-serif;
$primary: findDarkColor($red);
$tabs-link-active-color: $primary;
$tabs-link-active-border-bottom-color: $tabs-link-active-color;
$menu-item-active-background-color: $primary;

#app {
	min-height: 100vh;
}

@import "bulma/bulma.sass";
