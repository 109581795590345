.loadingIndicator:not(.minimal) {
	width: 100%;
	height: 100%;
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;

	.loadingIndicatorContent {
		text-align: center;
	}
}

.loadingIndicator.minimal {
	.loadingIndicatorContent {
		display: inline;
	}
}
